import React from "react";
import SEO from "../components/seo";
import SignUpButton from "../components/SignUpButton";
import Image from "../components/image";

const SlackIntegration = props => {
    return (
        <div className={"pb-32"}>
            <SEO title="Slack integration" />
            <div className="relative py-8 lg:p-16 xl:py-32 md:flex flex-row container mx-auto" style={{ minHeight: "100vh" }}>
                <div className={"container px-3 mx-auto flex flex-wrap flex-col-reverse  md:flex-row items-center h-full md:w-5/12"}>
                    <div className="flex flex-col w-full justify-center items-start mt-8 pt-16">
                        <h1 className="my-4 text-xl xsm:text-3xl md:text-4xl font-extrabold leading-tight">
                            Agile teams use Scrum Mate to make self-organization easy and effective
                        </h1>
                        <p className="leading-normal text-lg xsm:text-xl mb-8">
                            Scrum Mate integration with Slack notifies your team members of personal messages and publish board and backlog
                            events to a specified Slack channel.
                        </p>
                        <SignUpButton className={"mx-auto lg:mx-0 my-6"} />
                    </div>
                    <div className={"w-full md:w-1/2 lg:w-2/5 py-6 text-center"} />
                </div>
                <Image imgName={"flow-sitting"} className={"h-full md:w-7/12 ml-auto md:mt-64"} />
            </div>
            <section className={"overflow-hidden relative"}>
                <div className={"container mx-auto"}>
                    <div className={"flex flex-col justify-center mb-8 lg:mb-12 md:w-4/5 lg:w-3/5 mx-auto md:text-center px-4 md:px-8"}>
                        <h1 className={"text-xl md:text-2xl lg:text-3xl font-extrabold pb-4"}>Transparency. Communication.</h1>
                        <p>
                            Scrum Mate is designed to provide teams with a clear picture of what needs to be done, who should do it, and
                            what we have already achieved. With <strong>Slack</strong> Scrum Mate integration, teams can use Slack as a
                            unified communication channel for project status updates and personal interactions.
                        </p>
                    </div>
                </div>
            </section>
            <section className={"py-16 md:py-32"}>
                <div className={"container mx-auto flex flex-col-reverse md:flex-row relative"}>
                    <div className={"md:w-2/5 p-8 md:p-0"}>
                        <Image
                            style={{ maxWidth: 400, marginLeft: "auto" }}
                            imgName={"notifications"}
                            className={"w-full features-video-left"}
                        />
                    </div>
                    <div className={"md:w-3/5 flex flex-col justify-center mb-16 md:mb-0 px-4 md:px-8"}>
                        <h1 className={"text-xl md:text-2xl lg:text-3xl font-extrabold pb-4 relative"}>My notifications</h1>
                        <p className={"relative"}>
                            There are several events in Scrum Mate that may deserve your attention. You can choose to receive those
                            notifications via Slack direct message. Here are the events that will trigger a direct message if you want.
                        </p>
                        <ul className={"simple-ul"}>
                            <li>Someone assigns you to a user story or task</li>
                            <li>Someone mentions you in a comment</li>
                            <li>A sprint has been started or stopped</li>
                            <li>Someone modifies a user story you are subscribed to</li>
                        </ul>
                        <a href={"http://help.scrummate.com/article/145-slack-integration"} className={"text-blue-500 font-extrabold ml-10"}>
                            Installation guide >
                        </a>
                    </div>
                </div>
            </section>
            <section className={"py-16 md:pb-32 relative"}>
                <div className={"container mx-auto md:flex relative"}>
                    <div className={"md:w-2/5 flex flex-col justify-center mb-16 md:mb-0 px-4 md:px-8"}>
                        <h1 className={"text-xl md:text-2xl lg:text-3xl font-extrabold pb-4 relative"}>Project journal</h1>
                        <p className={"relative"}>
                            Team members can stay up to date on activities performed on any board or in any backlog. By forwarding the
                            activity logs to a Slack channel, you can gather all events into one place. Here are some of the events Scrum
                            Mate can forward to your team channel when someone:
                        </p>
                        <ul className={"simple-ul"}>
                            <li>Created or deleted a story or task</li>
                            <li>Changed the status of a story or task</li>
                            <li>Commented a story or task</li>
                            <li>Updated the description, name or estimated effort of a story or task</li>
                        </ul>
                        <a href={"http://help.scrummate.com/article/145-slack-integration"} className={"text-blue-500 font-extrabold ml-10"}>
                            Installation guide >
                        </a>
                    </div>
                    <div className={"md:w-3/5 p-8 md:p-0"}>
                        <Image imgName={"projectjournal"} className={"w-full"} />
                    </div>
                </div>
            </section>
            <div className={"text-center py-16"}>
                <h2 className={"text-2xl mb-8 mt-16"}>Start your free 15-day trial</h2>
                <SignUpButton className={""}>Sign Up</SignUpButton>
            </div>
        </div>
    );
};

export default SlackIntegration;
